// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-base-src-pages-404-js": () => import("./../../../node_modules/gatsby-theme-base/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-base-src-pages-404-js" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-talt-basta-kupoltaltet-2020-mdx": () => import("./../../../src/pages/talt/basta-kupoltaltet-2020.mdx" /* webpackChunkName: "component---src-pages-talt-basta-kupoltaltet-2020-mdx" */),
  "component---src-pages-talt-basta-tunneltaltet-2020-mdx": () => import("./../../../src/pages/talt/basta-tunneltaltet-2020.mdx" /* webpackChunkName: "component---src-pages-talt-basta-tunneltaltet-2020-mdx" */),
  "component---src-pages-talt-index-mdx": () => import("./../../../src/pages/talt/index.mdx" /* webpackChunkName: "component---src-pages-talt-index-mdx" */),
  "component---src-pages-talt-vilket-typ-av-talt-for-vandringen-mdx": () => import("./../../../src/pages/talt/vilket-typ-av-talt-for-vandringen.mdx" /* webpackChunkName: "component---src-pages-talt-vilket-typ-av-talt-for-vandringen-mdx" */)
}

